import React, { useEffect, useState } from 'react';
import 'foundation-sites/dist/css/foundation.min.css';
import $ from 'jquery';
import 'foundation-sites';
import img from "../../Assets/Navbar/Full_Logo.png"
import "./newnavbar.css";

const Newnavbar = () => {
  const [prevScrollTop, setPrevScrollTop] = useState(0);
  const [navHidden, setNavHidden] = useState(false);

  useEffect(() => {
    $(document).foundation();

    const handleScroll = () => {
      const scrollTop = $(window).scrollTop();
      setNavHidden(scrollTop > prevScrollTop);
      setPrevScrollTop(scrollTop);
    };

    $(window).on('scroll', handleScroll);

    return () => {
      $(window).off('scroll', handleScroll);
    };
  }, [prevScrollTop]);



  useEffect(() => {
    const navbar = document.getElementById("container");
    const scrollThreshold = 50;  // Change this value if you want to make the scroll threshold smaller/larger

    const handleScroll = () => {
      if (window.scrollY > scrollThreshold) {
        navbar.classList.add("fixed-top");
      } else {
        navbar.classList.remove("fixed-top");
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);





  return (
    <>
      {/* mobile nav */}
      <div id="container" className="grid-container newdesknav fixed-top2">
        <div className="grid-x">

          <nav className={`scrollhide-nav ${navHidden ? 'hidden' : ''}`}>
            <div className="title-bar" data-responsive-toggle="example-menu" data-hide-for="medium">
              <button className="menu-icon" type="button" data-toggle="example-menu"></button>
              <div className="title-bar-title">
                <img className="logonayak" src={img} alt="Logo" />
              </div>
            </div>




            <div id="container2" className='whiteBg'>
              <div className="top-bar innerTopbar" id="example-menu">
                <img className="mainLogo" src={img} alt="Logo" />
                <div className="top-bar-left">
                  <ul className="vertical medium-horizontal menu menu2" data-responsive-menu="accordion medium-dropdown">
                    <li>
                      <a className='resNavLink' href="/">Home</a>
                    </li>
                    <li>
                      <a className='resNavLink' href="/about">About Us</a>
                    </li>
                    <li>
                      <a className='resNavLink' href="#">Our Offerings</a>
                      <ul className="vertical menu">
                        <li>
                          <a className='resNavLink' href="#">School</a>
                          <ul className="vertical menu">
                            <li><a className='resNavLink' href="/cbse-classes">CBSE</a></li>
                            <li><a className='resNavLink' href="/icse-classes">ICSE</a></li>
                            <li><a className='resNavLink' href="/igcse-classes">IGCSE</a></li>
                            <li><a className='resNavLink' href="/classes-for-mh-ssc-board">SSC</a></li>
                            <li><a className='resNavLink' href="/olympiads">OLYMPIADS</a></li>
                          </ul>
                        </li>
                        <li>
                          <a className='resNavLink' href="#">College</a>
                          <ul className="vertical menu">
                            <li><a className='resNavLink' href="/eleven&Twelth">11<sup>th</sup> & 12<sup>th</sup></a></li>
                            <li><a className='resNavLink' href="/tybcom">TY Bcom</a></li>
                            <li>
                              <a className='resNavLink' href="#">Pre Engineering</a>
                              <ul className="vertical menu">

                                <li><a className='resNavLink' href="/ace-40-batch-for-iit-jeeintegrated">ACE 40 Batch for IIT-JEE (11<sup>th</sup> & 12<sup>th</sup>)</a></li>
                                <li><a className='resNavLink' href="/g-10-plus-batch-for-iit-jee-integrated">G-10 Plus Batch for IIT-JEE (11<sup>th</sup> & 12<sup>th</sup>)</a></li>
                                <li><a className='resNavLink' href="/enginner3">ACE 40 Batch for MHT-CET (11<sup>th</sup> & 12<sup>th</sup>)</a></li>
                              </ul>
                            </li>
                            <li>
                              <a className='resNavLink' href="#">Pre Medical</a>
                              <ul className="vertical menu">
                                <li><a className='resNavLink' href="/ace-40-batch-for-neet-ug-integrated">ACE 40 Batch for NEET-UG (11<sup>th</sup> & 12<sup>th</sup>)</a></li>
                                <li><a className='resNavLink' href="/g-10-plus-batch-for-neet-ugintegrated">G-10 Plus Batch for NEET-UG (11<sup>th</sup> & 12<sup>th</sup>)</a></li>
                                <li><a className='resNavLink' href="/medical3">ACE 40 Batch for MHT-CET (11<sup>th</sup> & 12<sup>th</sup>)</a></li>
                              </ul>
                            </li>
                            <li><a className='resNavLink' href="/ca">CA & CS</a></li>
                          </ul>
                        </li>
                        <li><a className='resNavLink' href="/class-10-score-booster-course">Score Booster</a></li>
                        <li><a className='resNavLink' href="/preschool">Pre-school</a></li>
                        <li><a className='resNavLink' href='https://careerprep.co.in/'>Career Prep</a></li>
                      </ul>
                    </li>
                    <li>
                      <a className='resNavLink' href="/testimonial">Success Stories</a>
                    </li>
                    <li>
                      <a className='resNavLink' href="/paper">Practice Papers</a>
                    </li>

                    <li>
                      <a className='resNavLink' href="/notes/matter-in-our-surroundings-class-9-science">Notes</a>
                    </li>
                    <li>
                      <a className='resNavLink' href="/mainfaq">FAQ</a>
                    </li>
                    <li>
                      <a className='resNavLink' href="/blog">Blog</a>
                    </li>
                    <li>
                      <a className='resNavLink' href="/contact">Contact Us</a>
                    </li>


                  </ul>
                </div>
              </div>
            </div>

            
          </nav>
        </div>
      </div>
      {/* mobile nav */}



    </>
  );
};

export default Newnavbar;
