import React from 'react'
import Newnavbar from '../newNavbar/Newnavbar'
import LogoContact from '../logoContact/logoContact'
import Footer from '../footer/Footer'
import "./subeng.css";

function Premedicalone() {
    return (
        <>
            <LogoContact />
            <Newnavbar />


            <div className='mainsubengdiv'>
                <div className='mainsubengdivinner'>
                    {/* <div style={{ marginBottom: "10px" }}><a href='/'>Home »</a><a href='/blog'>Our Offerings »</a><a>College »</a><a>Pre Engineering »</a><a>Engineering 1</a></div> */}
                    <h2>ACE 40 Batch for NEET-UG</h2>
                    <p>2 Years Integrated Classroom Course For Classes 11-12 (2025-27)</p>
                </div>
            </div>
            <div className='subengwhitediv'>
                <h3>ACE 40 BATCH FOR NEET-UG + MHT-CET</h3>
                <p>With the <b> ACE (Advance Course for Excellence) 40 Batch</b>, students can achieve their dream of becoming a Doctor from one of top medical colleges of India & abroad. They will be guided by a pool of experienced and qualified faculty team. Our faculty team will work closely with each student in the batch for better learning. Students would receive individualised attention from our faculty team. <br></br><br></br>

                    This course would certainly raise the level of the student by laying a strong foundation first and then putting him/her on the road map to success in a very logical & planned manner required to crack <b>NEET-UG</b> exam with good ranks.<br></br><br></br>


                    This course provides the widest possible coverage of concepts and inculcates an examination temperament. The continuous performance of students by our mentors would certainly enable students to achieve success in <b>NEET-UG</b> and also in <b>XII Board</b> exams.  Finally they get into one of  dream colleges like <b>AIIMS, JIPMER, AFMC, GMC, KJSMC </b>or other.<br></br><br></br>


                    Moreover, with the preparation of exam like <b>NEET-UG</b> Students also get prepared well for <b>MHT-CET.</b> </p>
            </div>

            <div className='subengbluedivmain'>
                <div className='subengbluediv'>
                    <h3>Course Overview</h3>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>For Classes</p></div>
                        <div className='subengblue2'><p>Along with Classes 11 – 12</p></div>
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>Course Code</p></div>
                        <div className='subengblue2'><p>1003</p></div>
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>For Academic Session</p></div>
                        <div className='subengblue2'><p>2025 – 2027</p></div>
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>Batch Type</p></div>
                        <div className='subengblue2'><p>A Batch of 40 Students</p></div>
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>Course Type</p></div>
                        <div className='subengblue2'><p>Integrated Course</p></div>
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>Lecture Language</p></div>
                        <div className='subengblue2'><p>Mixed (English / Hindi / Marathi)</p></div>
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>Study Materials, Tests & Notes</p></div>
                        <div className='subengblue2'><p>English Medium</p></div>
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>Board</p></div>
                        <div className='subengblue2'><p>CBSE, ICSE, Maharashtra Board</p></div>
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>Syllabus Coverage</p></div>
                        <div className='subengblue2'><p>NEET-UG + 11-12 School Board + Support for MHT-CET exam preparation</p></div>
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>Subjects Covered</p></div>
                        <div className='subengblue2'>
                            <p>Physics, Chemistry, Zoology & Botany</p>
                        </div>
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>Course Highlights</p></div>
                        <div className='subengblue2'>
                            <p>This is a 40 students’ batch for Advanced Course of Excellence (ACE), provided with:</p>
                            {/* <li className='subli2'>Classes by ITians, NITians & Qualified Faculty Team</li>
                            <li className='subli2'>Regular Theory Classes For NEET-UG + Board (Total 1000 Hours per year)</li>
                            <li className='subli2'>Equal weightage to Test and Class XI – XII Board Exam Preparation</li>
                            <li className='subli2'>Comprehensive Study Materials (NEET-UG + Board)</li>
                            <li className='subli2'>Rigourous Practice & Testing</li>
                            <li className='subli2'>Adaptive Assessment with AI based Learning App</li>
                            <li className='subli2'>Personalised Coaching & Care</li>
                            <li className='subli2'>Excellent Mentoring by experts and more</li> */}


                            <div className='subli2'>Classes by ITians, NITians & Qualified Faculty Team</div>
                            <div className='subli2'>Regular Theory Classes For NEET-UG + Board (Total 1000 Hours per year)</div>
                            <div className='subli2'>Self-study & Practice Sessions At Center.</div>
                            <div className='subli2'>Equal weightage to Test and Class XI – XII Board Exam Preparation</div>
                            <div className='subli2'>Comprehensive Study Materials (NEET-UG + Board)</div>
                            <div className='subli2'>Rigourous Practice & Testing</div>
                            <div className='subli2'>Adaptive Assessment with AI based Learning App</div>
                            <div className='subli2'>Personalised Coaching & Care</div>
                            <div className='subli2'>Excellent Mentoring by experts and more</div>

                        </div>
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>Admission Eligibility</p></div>
                        <div className='subengblue2'><p>Score in <b>taleNT2025</b> exam/ <b> NSAT</b> exam <b>/ Marks ≥ 70% </b> in 10th Board /<b> Past Academic</b> Performances.</p></div>
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>Admission Process</p></div>
                        <div className='subengblue2'><p>First come First Served Basis</p></div>
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>Scholarship</p></div>
                        <div className='subengblue2'><p>Up to <b>90% Scholarship</b> (applicable on course fee) based on <b>taleNT2025 / NSAT</b> exam Score / <b> Past Academic</b> Performances.</p></div>
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>Batches Start From </p></div>
                        <div className='subengblue2'><p>13-Apr-2025</p></div>
                    </div>
                    <div className='subengblueflexother'>
                        <p>Important:</p>
                        <div className='subli'><b>Course Fee Structure:</b> Total Course Fee has two parts. One is Admission cum Admin Fee (One Time & Non Refundable) and Second is Tuition Fee.</div>

                        <div className='subli'>
                            <b>Fee Mode: </b><b>i. Installment Mode:</b>  Two years full fee to be paid in 16 installments. <br></br>
                            <div><b>ii. Lumpsum Mode:</b> LUMPSUM Fee discount is additional 5% less on the applicable Tuition Fee.</div>
                        </div>

                        <div className='subli' style={{display:'block'}}> <b>First Installment:</b> In case of Installment fee scheme, first installment is to be paid at time of admission and rest installments are to be paid in subsequent months.</div>

                        <div className='subli'><b>GST: </b>Total Fee Amount is inclusive of GST @ 18% (Present Rate) and supply of Study Materials(English Medium).</div>

                        <div className='subli'><b>Fee: </b>Course Fee may be changed, if there would be any levy/change in taxes by the Central/State Govt.</div>

                        <div className='subli'><b>PDCs: </b>In the case of installment payment option, students have to deposit PDC (Post Dated Cheque) of balance amount at the time of first installment deposit.</div>

                        <div className='subli'><b>Seats:</b> Admission in a particular course at a particular location is subjected to availability of seats.</div>

                        <div className='subli'><b>Fee Receipt:</b> Keep your fee receipt safely, in many cases it will work as an authentic proof of your admission.</div>
                        
                        <div className='subli'><b>Refund Policy:</b> Refund Application (In the standard Format) has to be applied and further the refund will be processed on the Tuition Fee paid as per the standard coaching guidelines.</div>
                    </div>

                </div>
            </div>


            <Footer />
        </>
    )
}

export default Premedicalone
