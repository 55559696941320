import React from 'react'
import Newnavbar from '../newNavbar/Newnavbar'
import LogoContact from '../logoContact/logoContact'
import Footer from '../footer/Footer'
import "./subeng.css";

function Premedicalthree() {
    return (
        <>
            <LogoContact />
            <Newnavbar />


            <div className='mainsubengdiv'>
                <div className='mainsubengdivinner'>
                    {/* <div style={{ marginBottom: "10px" }}><a href='/'>Home »</a><a href='/blog'>Our Offerings »</a><a>College »</a><a>Pre Engineering »</a><a>Engineering 1</a></div> */}
                    <h2>ACE 40 Batch for MHT-CET</h2>
                    <p>2 Years Integrated Classroom Course For Classes 11-12(2025-27)</p>
                </div>
            </div>
            <div className='subengwhitediv'>
                <h3>ACE 40 BATCH FOR MHT-CET + 11-12 Board</h3>
                <p>A team of highly experienced teaching faculty members has been put together who are particularly familiar with the <b>MHT-CET</b> exam pattern.<br></br><br></br>

                    The course is specially designed for those who have just passed Class X and want to get into reputed Engineering, Pharma or other specialisation colleges in Maharashtra through <b>MHT-CET.</b><br></br><br></br>

                    Through concept learning, students will be placed on the charted path to success. Apart from online/offline tests on the actual MHT-CET pattern, all students will receive proper guidance by our faculty with special tips and tricks apart from comprehensive study materials, DPP etc., which will enhance their ability to solve papers quickly and accurately to ensure excellent performances in <b>MHT-CET</b> and <b> 12 Board exams.</b> Finally they get into one of  the reputed <b> Govt. Engineering, Architecture, Pharma, Agriculture, Food </b> or other specialised <b>Colleges</b> in <b>Maharashtra.</b> </p>
            </div>

            <div className='subengbluedivmain'>
                <div className='subengbluediv'>
                    <h3>Course Overview</h3>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>For Classes</p></div>
                        <div className='subengblue2'><p>Along with Classes 11 – 12</p></div>
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>Course Code</p></div>
                        <div className='subengblue2'><p>1005</p></div>
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>For Academic Session</p></div>
                        <div className='subengblue2'><p>2025 – 2027</p></div>
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>Batch Type</p></div>
                        <div className='subengblue2'><p>A Batch of 40 Potential Students</p></div>
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>Course Type</p></div>
                        <div className='subengblue2'><p>Integrated Course</p></div>
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>Lecture Language</p></div>
                        <div className='subengblue2'><p>Mixed (English / Hindi / Marathi)</p></div>
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>Study Materials, Tests & Notes</p></div>
                        <div className='subengblue2'><p>English Medium</p></div>
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>Board</p></div>
                        <div className='subengblue2'><p>Maharashtra Board</p></div> 
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>Syllabus Coverage</p></div>
                        <div className='subengblue2'><p>MHT-CET + 11-12 School Board</p></div>
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>Course Highlights</p></div>
                        <div className='subengblue2'>
                            <p>This is a 40 students’ batch for Advanced Course of Excellence (ACE), provided with:</p>
                            <div className='subli2'>Experienced & qualified faculty team</div>
                            <div className='subli2'>Regular Theory Classes (6 days a week).</div>
                            <div className='subli2'>Comprehensive Study Materials</div>
                            <div className='subli2'>Rigorous Practice & Testing (Weekly, Minor & Major)</div>
                            <div className='subli2'>Adaptive Assessment</div>
                            <div className='subli2'>Personalised Coaching & Care</div>
                            <div className='subli2'>Excellent Mentoring by Experts</div>
                            <div className='subli2'>Regular updates to parent and more. </div>
                            </div>
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>Subjects Covered </p></div>
                        <div className='subengblue2'><p>PCB / PCMB</p></div>
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>Admission Eligibility</p></div>
                        <div className='subengblue2'><p>10th Board Pass / Past Academic  Performances</p></div>
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>Admission Process</p></div>
                        <div className='subengblue2'><p>First come First Served Basis</p></div>
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>Scholarship</p></div>
                        <div className='subengblue2'><p>Up to <b>90% Scholarship</b> (applicable on course fee) based on <b>taleNT2025 / NSAT</b> exam Score / <b> Past Academic</b> Performances.</p></div>
                    </div>
                    <div className='subengblueflex'>
                        <div className='subengblue1'><p>Batches Start From </p></div>
                        <div className='subengblue2'><p>04-June-2024</p></div>
                    </div>
                    <div className='subengblueflexother'>
                        <p>Important:</p>
                        <div className='subli'><b>Course Fee Structure:</b> Total Course Fee has two parts. One is Admission cum Admin Fee (One Time & Non Refundable) and Second is Tuition Fee.</div>

                        <div className='subli'>
                            <b>Fee Mode: </b><b>i. Installment Mode:</b>  Two years full fee to be paid in 16 installments. <br></br>
                            <div><b>ii. Lumpsum Mode:</b> LUMPSUM Fee discount is additional 5% less on the applicable Tuition Fee.</div>
                        </div>

                        <div className='subli' style={{display:'block'}}> <b>First Installment:</b> In case of Installment fee scheme, first installment is to be paid at time of admission and rest installments are to be paid in subsequent months.</div>

                        <div className='subli'><b>GST: </b>Total Fee Amount is inclusive of GST @ 18% (Present Rate) and supply of Study Materials(English Medium).</div>

                        <div className='subli'><b>Fee: </b>Course Fee may be changed, if there would be any levy/change in taxes by the Central/State Govt.</div>

                        <div className='subli'><b>PDCs: </b>In the case of installment payment option, students have to deposit PDC (Post Dated Cheque) of balance amount at the time of first installment deposit.</div>

                        <div className='subli'><b>Seats:</b> Admission in a particular course at a particular location is subjected to availability of seats.</div>

                        <div className='subli'><b>Fee Receipt:</b> Keep your fee receipt safely, in many cases it will work as an authentic proof of your admission.</div>
                        
                        <div className='subli'><b>Refund Policy:</b> Refund Application (In the standard Format) has to be applied and further the refund will be processed on the Tuition Fee paid as per the standard coaching guidelines.</div>
                    </div>

                </div>
            </div>


            <Footer />
        </>
    )
}

export default Premedicalthree
